<template>
  <div>
    <div>
      <b-container fluid>
        <b-row v-if="panelObj.divisionalHeadLoader">
          <b-col class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
            <br/>
            {{ panelObj.divisionalHeadMessage }}
          </b-col>
        </b-row>
        <b-row v-else-if="panelObj.divisionalHeadMessage !== ''">
          <div class="box-message col-md-12 mt-5 text-danger">
            <div class="message-body-center">
                <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>{{ panelObj.divisionalHeadMessage }}</p>
            </div>
          </div>
        </b-row>
        <b-row v-else>
            <b-col>
                <b-card class="my-card">
                    <h3 class="text-white t-shadow">{{ $t('externalUserIrrigation.hello')}} <span class="text-info t-shadow" v-if="this.$i18n.locale === 'bn'">{{ authUser.name_bn }}</span> <span class="text-info t-shadow" v-if="this.$i18n.locale === 'en'">{{ authUser.name }}</span>!</h3>
                    <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'bn'"><span class="text-danger">কৃষি মন্ত্রণালয়</span> এর <span class="text-warning">ইন্টিগ্রেটেড ডিজিটাল পরিষেবা ডেলিভারি প্ল্যাটফর্ম</span> এ আপনাকে স্বাগতম</h3>
                    <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'en'">Welcome to <span class="text-warning">Integrated Digital Service Delivery Platform Service</span> of <span class="text-danger">Ministry of Agriculture</span></h3>
                    <h4 class="text-white t-shadow">{{ $t('externalUserIrrigation.your_all_service_here') }} <i class="ion-heart text-danger"></i><div data-icon="i" class="icon"></div></h4>
                </b-card>
            </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
// import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
// import { farmerInfo, userStatus, warehouseSpaceStatus, deliveryScheduleList } from '../../../api/routes'

export default {
  data () {
    return {
      loadingState: false,
      overlay: true,
      user: {}
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser',
      panelObj: 'ExternalUserIrrigation/panelObj'
    }),
    authUser () {
       return this.$store.state.Auth.authUser
    }
  },
  mounted () {
    core.index()
  },
  methods: {}
}
</script>

<style scoped>
.min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-image: url("../../../../../../assets/images/login/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
